
  import { reactive, ref, onMounted } from 'vue'
  import { useStore } from "vuex";
  import { Actions } from "@/store/enums/SupplierEnums.ts";
  import { useRouter } from "vue-router";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  export default {
    name : 'SupplierAdd',
    setup(){

      onMounted(() => {
        setCurrentPageBreadcrumbs("Suppliers",[
          {title : "Suppliers",to : "/suppliers/list"},
          {title : "New Supplier",to : "/suppliers/add"}
        ]);
      });

      const store = useStore();
      const router = useRouter();

      const submitButton = ref<HTMLElement | null>(null);

      const success = ref(false)

      const errors = reactive({
        value : {}
      });

      const formData = reactive({
        name : '',
        address1 : '',
        address2 : '',
        city : '',
        county : '',
        country : '',
        postode : '',
        email : '',
        mobile : '',
        telephone : '',
        isCustomer : false
      })

      const submitForm = () => {

        if(submitButton.value){
          submitButton.value.setAttribute("data-kt-indicator", "on");
        }

        store.dispatch(Actions.ADD_SUPPLIER,formData).then(() => {

          success.value = true;
          errors.value = [];
          submitButton.value?.removeAttribute("data-kt-indicator");
          window.scrollTo(0,0);
          setTimeout(function(){
            router.push('/suppliers/list')
          },1000)

        }).catch((response) => {
          setTimeout(function(){
            const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
            input.focus();
          },100)

          errors.value = response.data.errors;

          submitButton.value?.removeAttribute("data-kt-indicator");

        })
      }

      return {
        submitButton,
        formData,
        submitForm,
        errors,
        success
      }
    }
  }
